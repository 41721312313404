
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { ICustomer, ICustomerListRequest } from "@/types/customer";
import {
  IBranch,
  IBranchCreateRequest,
  IBranchRequest,
  IBranchUpdateRequest
} from "@/types/branch";
import { BranchRequest } from "@/models/branch";
import { IUser, IUserListRequest } from "@/types/user";
import { IZipaddress } from "@/types/zipaddress";
import {
  IMoneyforward,
  IMoneyforwardDepartmentCreateRequest,
  IMoneyforwardDepartmentUpdateRequest
} from "@/types/moneyforward";
import {
  MoneyforwardDepartmentCreateRequest,
  MoneyforwardDepartmentUpdateRequest
} from "@/models/moneyforward";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //branch
  @Action("branch/adminGet")
  public getBranch!: (branch_id: number) => Promise<boolean>;

  @Action("branch/adminCreate")
  public create!: (params: {
    branches: IBranchCreateRequest[];
  }) => Promise<boolean>;

  @Action("branch/adminUpdate")
  public update!: (data: {
    branch_id: number;
    params: IBranchUpdateRequest;
  }) => Promise<boolean>;

  @Action("branch/adminDelete")
  public deleteBranch!: (branch_id: number) => Promise<boolean>;

  @Action("branch/adminIsUniqueName")
  public isUniqueName!: (params: IBranchRequest) => Promise<boolean>;

  @Action("branch/adminIsUniqueShortName")
  public isUniqueShortName!: (params: IBranchRequest) => Promise<boolean>;

  @Getter("branch/single")
  public branch!: IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  //zipaddress
  @Action("zipaddress/getZipaddress")
  public getZipaddress!: (zipcode: string) => Promise<boolean>;

  @Getter("zipaddress/single")
  public zipaddress!: IZipaddress;

  @Mutation("zipaddress/clear")
  public clearZipaddress!: () => void;

  //moneyforward
  @Action("moneyforward/getRefreshToken")
  public getRefreshToken!: () => Promise<boolean>;

  @Action("moneyforward/getReToken")
  public getReToken!: () => Promise<boolean>;

  @Action("moneyforward/createRefreshToken")
  public createRefreshToken!: () => Promise<boolean>;

  @Action("moneyforward/adminCreateDepartment")
  public createDepartment!: (data: {
    partner_id: string;
    params: IMoneyforwardDepartmentCreateRequest;
  }) => Promise<boolean>;

  @Action("moneyforward/adminUpdateDepartment")
  public updateDepartment!: (data: {
    partner_id: string;
    department_id: string;
    params: IMoneyforwardDepartmentUpdateRequest;
  }) => Promise<boolean>;

  @Action("moneyforward/adminDeleteDepartment")
  public deleteDepartment!: (data: {
    partner_id: string;
    department_id: string;
  }) => Promise<boolean>;

  @Getter("moneyforward/single")
  public mf_response!: IMoneyforward;

  //パラメータ定義
  public params: IBranchRequest = new BranchRequest();
  public create_mf_params: IMoneyforwardDepartmentCreateRequest =
    new MoneyforwardDepartmentCreateRequest();
  public update_mf_params: IMoneyforwardDepartmentUpdateRequest =
    new MoneyforwardDepartmentUpdateRequest();

  //変数定義
  public branch_id = 0;
  public customer_id = 0;
  public partner_id = "";
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public name_errors: string[] = [];
  public short_name_errors: string[] = [];
  public postalcode_errors: string[] = [];

  //ルール設定
  public rules = {
    name: [
      (v: string) => !!v || "支店名は必須です",
      (v: string) =>
        (v && v.length <= 50) || "支店名は50文字以下で入力してください"
    ],
    kana: [
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    short_name: [
      (v: string) => !!v || "短縮表示名は必須です",
      (v: string) =>
        (v && v.length <= 20) || "短縮表示名は20文字以下で入力してください"
    ],
    email: [
      (v: string) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "正しいメールアドレスを入力してください"
    ],
    prefecture: [(v: string) => !!v || "都道府県は必須です"],
    address1: [(v: string) => !!v || "住所は必須です"],
    tel: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    fax: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    customer_id: [(v: number) => !!v || "顧客は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearBranch();
    this.clearCustomer();
    this.clearZipaddress();
    this.branch_id = Number(this.$route.params.branch_id) || 0;
    this.params.customer_id = Number(this.$route.params.customer_id) || 0;

    await Promise.all([
      this.getCustomers({ per_page: 0 }),
      this.getRefreshToken()
    ]);
    await this.getReToken();
    await this.createRefreshToken();

    // 新規作成時
    if (this.params.customer_id) {
      await this.getUsers({ customer_id: this.params.customer_id });
    }

    // 編集時
    if (this.branch_id) {
      await this.getBranch(this.branch_id);
      await this.getUsers({ customer_id: this.branch.customer_id });

      this.setDefault();
    }

    this.partner_id = this.findCustomer(this.params.customer_id).uuid;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    if (!this.params) {
      return;
    }
    this.params.createFromBranch(this.branch);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    // 更新 ---------------------------------------------------------------------
    if (this.branch_id) {
      // マネーフォワード連携 ===================
      this.update_mf_params.createFromBranch(this.params);

      result = await this.updateDepartment({
        partner_id: this.partner_id,
        department_id: this.params.uuid,
        params: this.update_mf_params
      });
      // =====================================

      if (result) {
        result = await this.update({
          branch_id: this.branch_id,
          params: this.params
        });
      }
    }
    // ----------------------------------------------------------------------------

    // 登録 ---------------------------------------------------------------------
    if (!this.branch_id) {
      // マネーフォワード連携 ===================
      this.create_mf_params.createFromBranch(this.params);

      result = await this.createDepartment({
        partner_id: this.partner_id,
        params: this.create_mf_params
      });
      //=====================================

      this.params.uuid = this.mf_response.id;

      if (result) {
        result = await this.create({ branches: [this.params] });
      }
    }
    // ----------------------------------------------------------------------------

    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    let result = false;

    // マネーフォワード連携===========================================
    result = await this.deleteDepartment({
      partner_id: this.partner_id,
      department_id: this.params.uuid
    });
    //================================================================

    if (result) {
      await this.deleteBranch(this.branch_id);
    }

    if (result) {
      this.$router.go(-1);
    }
  }

  // 支店名の二重登録チェック
  public async name_validate() {
    // 登録時
    if (!(await this.isUniqueName(this.params)) && !this.branch.name) {
      return (this.name_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueName(this.params)) &&
      this.branch.name != this.params.name
    ) {
      return (this.name_errors = ["既に使用されています"]);
    }

    return (this.name_errors = []);
  }

  // 短縮表示名の二重登録チェック
  public async short_name_validate() {
    // 登録時
    if (
      !(await this.isUniqueShortName(this.params)) &&
      !this.branch.short_name
    ) {
      return (this.short_name_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueShortName(this.params)) &&
      this.branch.short_name != this.params.short_name
    ) {
      return (this.short_name_errors = ["既に使用されています"]);
    }

    return (this.short_name_errors = []);
  }

  // 住所自動入力
  public async changePostalCode(postalcode: string) {
    if (!postalcode) {
      this.postalcode_errors = [];
      return;
    }

    const regexp = new RegExp(/^[0-9]+$/);
    const result = postalcode.match(regexp);
    if (!result) {
      this.postalcode_errors = ["半角数字のみで入力してください(ハイフン不要)"];
      return;
    }

    await this.getZipaddress(postalcode);

    if (this.zipaddress.pref) {
      this.params.prefecture = this.zipaddress.pref;
      this.params.address1 = this.zipaddress.city + this.zipaddress.town;
      this.postalcode_errors = [];
    }

    if (!this.zipaddress.pref) {
      this.postalcode_errors = ["郵便番号が間違っています"];
    }

    if (this.zipaddress.office) {
      this.params.name = this.zipaddress.office;
    }
  }
}
